<template>
    <div class="data_pagination mt24">
        <div class="text">共 {{total}} 条记录，第 {{current}} / {{pages}} 页</div>
        <el-pagination :class="{buying: isBuying, supply: isSupply}" background layout="prev, pager, next, jumper" :page-size="size" :total="total" :current-page="current" :page-sizes="[10, 20, 30]" @current-change="currentChange"></el-pagination>
    </div>
</template>

<script>
  export default {
    name: "pagination",
    props: {
      size: {
        type: Number,
        default: 0
      },
      total: {
        type: Number,
        default: 0
      },
      current: {
        type: Number,
        default: 0
      },
      pages: {
        type: Number,
        default: 0
      },
      isBuying: {
        type: Boolean,
        default: false
      },
      isSupply: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      currentChange (e) {
        this.$emit('pageChange', {current: e, size: this.size})
      }
    }
  }
</script>
